<template>
  <div class="flex-column video">
    <div style="position: relative;width: 100%;height: 100%;background: #f8f8f8;border-radius: 12px;" class="flex-column">
      <el-image style="width: 100%;height: 100%;margin: 0px auto;border-radius: 10px;"
        :src="data.url"
        v-if="data && data.url"
        :preview-src-list="[data.url]"
        fit="cover" ></el-image>
      <div style="position: absolute;right: 20px; bottom: 20px;">
        <div class="info">上次更新时间：{{ data ? data.time : '-' }} <li :class="loading?'el-icon-loading':'el-icon-refresh-right'" style="cursor: pointer;" @click="refresh()"></li></div>
      </div>
    </div>
 </div>
</template>

<script>
export default {
  props: {
    picData: Object,
    equip: Object
  },
  data () {
    return {
      loading: false,
      data: null
    }
  },
  watch: {
    picData (newValue, oldValue) {
      this.data = newValue
      this.loading = false
      clearTimeout(this.timeVerCheck)
    },
    equip (newValue, oldValue) {
      this.getData(newValue.devicecode)
    }
  },
  mounted () {
    this.data = this.picData
    this.getData((this.equip || {}).devicecode)
  },
  beforeDestroy () {
    clearTimeout(this.timeVerCheck)
  },
  methods: {
    async getData (deviceCode, force = 0) {
      if (!deviceCode) {
        return
      }
      clearTimeout(this.timeVerCheck)
      this.loading = true
      const last = { ...this.data }
      try {
        this.data = {
          url: '',
          time: '更新中'
        }
        const baseUrl = this.$store.state.baseUrl
        var { data: res } = await this.$http.post(`${baseUrl}camera-pic/${deviceCode}?parkCode=${window.localStorage.getItem('LOGIN_PACKCODE')}&force=${force}`)
        if (!res.data || force === 1) {
          const that = this
          this.timeVerCheck = setTimeout(() => {
            that.loading = false
            last.time = '更新失败'
            this.data = last
          }, 8 * 1000)
          return
        }
        this.loading = false
        this.data = {
          url: res.data.imagePath,
          time: res.data.time
        }
      } catch (e) {
        last.time = '更新失败'
        this.data = last
        this.loading = false
      }
    },
    refresh () {
      if (this.loading) {
        return
      }
      this.getData((this.equip || {}).devicecode, 1)
    }
  }
}
</script>

<style>
  .info {
    backdrop-filter: blur(52px);
    -webkit-backdrop-filter: blur(52px);
    padding:5px 10px;
    border-radius: 12px;
    background: rgba(0, 0, 0, 0.15);
    box-shadow:  4px 4px 4px rgba(0, 0, 0, 0.15);
    color: #fff;
    font-size: 12px;
  }
</style>
