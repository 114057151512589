<template>
  <div>
    <el-form style="width: 500px" ref="ruleForm" :model="form" label-width="80px" :rules="rules">
      <el-form-item label="车牌号码" prop="plateNo">
        <el-input v-model="form.plateNo" clearable></el-input>
      </el-form-item>
      <el-form-item label="时间范围" prop="dateRange">
        <el-date-picker
            style="width: 100%;"
            v-model="form.dateRange"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:SS"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
      </el-form-item>
      <el-form-item label="作用区域" prop="area">
          <el-cascader
            clearable
            style="width: 100%;"
            v-model="form.area"
            :options="areaListOptions"></el-cascader>
        </el-form-item>
      <el-form-item label="车型" prop="plateType">
        <el-select v-model="form.plateType" placeholder="请选择" style="width: 100%;">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="免费时段" prop="freePeriod">
        <el-date-picker
            style="width: 100%;"
            v-model="form.freePeriod"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:SS"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
      </el-form-item>
    </el-form>
    <div class="flex-column">

      <div><el-button type="primary" @click="onFormCheck('normal')" :loading="searchLoading" style="margin-bottom: 10px;">计算费用</el-button>
        <el-button type="primary" @click="onFormCheck('quick')" :loading="searchLoading" style="margin-bottom: 10px;">快速计算费用</el-button></div>
      <div><el-link type="danger"  @click="dialogVisible = true">测试订单修改</el-link></div>
    </div>
    <div style="margin: 10px 0;">
      <el-alert :closable="false" type="success" v-if="ceOrderInfo">
        <template slot='title'>
          <div class="tag-title">开始时间：{{ ceOrderInfo.startDatetm }}</div>
          <div class="tag-title">结束时间：{{ ceOrderInfo.endDatetm }}</div>
          <div class="tag-title">共计时间：{{ ceOrderInfo.totalMinute }}分钟</div>
          <div class="tag-title">共计价格：{{ ceOrderInfo.orderPrice }}</div>
        </template>
      </el-alert>
    </div>
    <el-card header="价格明细表" v-if="ceOrderInfo">

      <el-table :data="pelletList" v-if="pelletList && pelletList.length > 0">
        <el-table-column
          prop="pelletSeq"
          label="序列">
        </el-table-column>
        <el-table-column
          prop="startDatetm"
          label="开始时间">
          <template slot-scope="scope">
            <div class="flex-row">
              <el-tag size="medium" type="warning">{{ scope.row.startDatetm }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="endDatetm"
          label="结束时间">
          <template slot-scope="scope">
            <div class="flex-row">
              <el-tag size="medium" type="warning">{{ scope.row.endDatetm }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="unitPrice"
          label="价格">
          <template slot-scope="scope">
            <div class="flex-row">
              <el-tag size="medium">{{ scope.row.unitPrice }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="campaignPrice"
          label="活动价格">
          <template slot-scope="scope">
            <div class="flex-row">
              <el-tag size="medium">{{ scope.row.campaignPrice }}</el-tag>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="lastPrice"
          label="共计">
          <template slot-scope="scope">
            <div class="flex-row">
              <el-tag size="medium" type="success">{{ scope.row.lastPrice }}</el-tag>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <el-table :data="ceOrderInfo.zoneList" v-if="ceOrderInfo.zoneList && ceOrderInfo.zoneList.length > 0">
        <el-table-column type="expand">
          <template slot-scope="props">
            {{ props.pellets }}
           <el-table :data="props.row.pellets">
            <el-table-column
              prop="begin"
              label="开始时间">
            </el-table-column>
            <el-table-column
              prop="end"
              label="结束时间">
            </el-table-column>
            <el-table-column
              prop="price"
              label="价格">
            </el-table-column>
            <el-table-column
              prop="seq"
              label="seq">
            </el-table-column>
            <el-table-column
              prop="beginMin"
              label="beginMin">
            </el-table-column>
            <el-table-column
              prop="endMin"
              label="endMin">
            </el-table-column>

           </el-table>
          </template>
        </el-table-column>

        <el-table-column
          prop="begin"
          label="开始时间">
        </el-table-column>
        <el-table-column
          prop="end"
          label="结束时间">
        </el-table-column>
        <el-table-column
          prop="freeCode"
          label="freeCode">
        </el-table-column>
        <el-table-column
          prop="free"
          label="free">
        </el-table-column>
        <el-table-column
          prop="beginMin"
          label="beginMin">
        </el-table-column>
        <el-table-column
          prop="endMin"
          label="endMin">
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog
      title="修改测试订单"
      :visible.sync="dialogVisible"
      :before-close="dialogClose"
      width="370px">
      <el-alert
        type="warning"
        :closable="false">
        <template slot='title'>
          <div>1、修改已入场的订单，改为提前2两小时入场时间。</div>
          <div>2、请确保该订单的车辆为测试车辆。</div>
        </template>
      </el-alert>
      <el-form ref="form" label-width="80px" style="margin-top: 20px;">
        <el-form-item label="订单编号">
          <el-input v-model="orderNo" clearable></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="changeOrderInTime">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
// import { areaCarType } from '../../utils/FeeUtil'

export default {
  name: 'chargetest',
  data () {
    return {
      dialogVisible: false,
      changeLoading: false,
      orderNo: '',
      form: {
        plateNo: '临A00001',
        dateRange: '',
        freePeriod: '',
        area: [],
        plateType: null
      },
      options: [
        {
          label: '小型车《蓝牌》',
          value: '7201'
        },
        {
          label: '中型车',
          value: '7202'
        },
        {
          label: '大型车《黄牌》',
          value: '7203'
        },
        {
          label: '新能源车《白绿牌》',
          value: '7204'
        },
        {
          label: '大型车新能源车《黄绿牌》',
          value: '7205'
        }
      ],
      areaListOptions: [],
      searchLoading: false,
      rules: {
        plateNo: [
          { required: true, message: '请输入', trigger: 'blur' }
        ],
        dateRange: [
          { required: true, message: '请输入', trigger: 'blur' }
        ],
        area: [
          { required: true, message: '请输入', trigger: 'blur' }
        ],
        plateType: [
          { required: true, message: '请输入', trigger: 'blur' }
        ]
      },
      baseUrl: '',
      ceOrderInfo: null,
      pelletList: []
    }
  },
  created () {
    this.baseUrl = this.$store.state.baseUrl.replace('/api/client', '')
    this.getListInfo()
  },
  methods: {
    dialogClose () {
      this.orderNo = ''
      this.dialogVisible = false
    },
    async changeOrderInTime () {
      if (this.orderNo <= 0) {
        this.$message.error('请输入订单编号')
        return
      }

      this.changeLoading = true
      try {
        const parkCode = window.localStorage.getItem('LOGIN_PACKCODE')
        var { data: res } = await this.$http.post(this.$store.state.baseUrl + `two-hours-ahead?parkCode=${parkCode}&orderNo=${this.orderNo}`, {
          params: { parkCode: parkCode, orderNo: this.orderNo }
        })
        this.changeLoading = false
        if (res.code === 1) {
          this.$message.success('修改成功')
          this.dialogClose()
        } else {
          this.$message.error(res.msg || res.message)
        }
      } catch (e) {
        console.log(e)
        this.changeLoading = false
        this.$message.error('修改失败')
      }
    },
    // 查询所有区域信息
    async getListInfo () {
      var { data: res } = await this.$http.get(this.$store.state.baseUrl + 'queryrRegions', {
        params: { parkCode: window.localStorage.getItem('LOGIN_PACKCODE') }
      })
      res = JSON.parse(res)
      // console.log(res)
      if (res.code === '0') {
        // 查询成功，设置数据
        const list = []
        for (let i = 0; i < res.data.length; i++) {
          const item = {
            value: res.data[i].regionCode,
            label: res.data[i].regionName
            // children: areaCarType()
          }
          list.push(item)
        }
        this.areaListOptions = list
      } else {
        this.$message.error(res.message)
      }
    },
    async onFormCheck (type) {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            this.searchLoading = true
            const data = {
              ceCode: window.localStorage.getItem('LOGIN_PACKCODE'),
              ceSubBlockCode: this.form.area[0],
              // ruleApplyObj: this.form.area[1],
              plateNo: this.form.plateNo,
              plateType: this.form.plateType,
              startDateTime: this.form.dateRange[0],
              stopDateTime: this.form.dateRange[1],
              freePeriods: [[this.form.freePeriod[0], this.form.freePeriod[1]]]
            }
            var url = 'chargeRule/chargeSimulate'
            if (type === 'quick') {
              url = 'chargeRule/quick-charge-simulate'
            }
            var { data: res } = await this.$http.post(this.baseUrl + url, data)
            this.searchLoading = false
            if (res.code === 200) {
              this.pelletList = res.data.pelletList
              const info = res.data.ceOrderInfo || {}
              const d1 = new Date(this.form.dateRange[0])
              const d2 = new Date(this.form.dateRange[1])
              info.totalMinute = parseInt(d2 - d1) / 1000 / 60
              if (type === 'quick') {
                info.startDatetm = d1
                info.endDatetm = d2
                info.orderPrice = res.data.price
                info.zoneList = res.data.zoneList
              }
              this.ceOrderInfo = info
            } else {
              this.$message.error(res.msg)
              this.ceOrderInfo = null
              this.pelletList = []
            }
          } catch (e) {
            this.searchLoading = false
            this.$message.error(e.message)
            this.ceOrderInfo = null
            this.pelletList = []
          }
        } else {
          this.$message.error('请完善参数配置')
          this.ceOrderInfo = null
          this.pelletList = []
        }
        return false
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .tag-title{
    font-size: 20px;
    margin: 10px 0 ;
  }
</style>
